<template>
  <div></div>
</template>

<script>
export default {
    name: 'Testimonials'

}
</script>

<style>

</style>